<template>
  <auth-container>
    <template #authForm>
      <p class="has-text-weight-semibold" style="margin-bottom: 1rem">
        {{ $t('You are now logged out.') }}
      </p>
      <strong>
        <router-link :to="{ path: '/' }">{{ $t('Return home') }}</router-link>
      </strong>
    </template>
  </auth-container>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import AuthContainer from './AuthContainer';

export default defineComponent({
  components: {
    AuthContainer,
  },
});
</script>
